<template>
  <div class="card" :class="classStatus">
    <div class="loading" v-if="isLoading"></div>
    <div class="status"></div>
    <div class="grid-x">
      <div v-if="isBlank" class="loader-wrapper">
        <card-loader/>
      </div>
      <template v-else>
        <div class="left-panel cell auto">
          <h1
            v-if="!editName"
            @click="openInputName"
            class="title">{{ campaign.name }}
            <pencil-icon/>
          </h1>
          <div
            v-else
            class="input-block">
            <input
              ref="inputName"
              v-model.trim="inputName"
              @keyup.enter="saveName"
              class="input-title"
              :class="{ 'has-error': hasError }"
              type="text"/>

            <button
              @click="saveName"
              class="button-icon success">
              <check-icon/>
            </button>

            <button
              @click="cancelEditName"
              class="button-icon danger">
              <close-icon/>
            </button>
          </div>
          <div class="data">
            <span class="bold">Status:</span>
            <span class="bold is-active">{{ statusLabel }}</span>
          </div>
          <div class="data">
            <span>Administradores:</span>
            <span>{{ campaign.admins }}</span>
          </div>
          <div class="data">
            <span>Agentes:</span>
            <span>{{ campaign.agents }}</span>
          </div>
          <div class="data">
            <span>Canales:</span>
            <channel-badge
              v-for="(channel, index) in channelTypes"
              :key="index"
              :type="channel"
              class="badge"/>
          </div>
        </div>

        <div class="right-panel cell shrink">
          <span>{{ createdAt }}</span>
          <span
            v-if="showToggleButton"
            @click="toggleCampaign"
            class="cta-toggle">{{ toggleLabel }}</span>
          <span
            v-if="isOwner"
            @click="openEdit"
            class="cta-edit">Editar</span>
        </div>

        <div class="actions cell">
          <div class="grid-x grid-margin-x">
            <div class="cell small-6">
              <button
                v-if="false"
                class="button fill primary expanded">Configurar canales</button>
            </div>
            <div class="cell small-6">
              <button
                @click="openCampaignConfiguration"
                class="button fill expanded">Configurar campaña
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ChannelBadge: () => import('@/components/ChannelBadge.vue'),
    CardLoader: () => import('@/components/loaders/CampaignCard.vue'),
  },
  props: {
    campaign: {
      required: true,
    },
    isOwner: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      editName: false,
      inputName: '',
      hasError: false,
    };
  },
  computed: {
    isBlank() {
      return !!this.campaign.isBlank;
    },
    isLoading() {
      return !!this.campaign.loading;
    },
    isActive() {
      return !!this.campaign.active;
    },
    statusLabel() {
      return this.isActive ? 'Activa' : 'Inactiva';
    },
    toggleLabel() {
      return this.isActive ? 'Desactivar' : 'Activar';
    },
    showToggleButton() {
      return this.isActive || this.isOwner;
    },
    channels() {
      return this.campaign.channels;
    },
    channelTypes() {
      return this.channels.map(c => c.channelType.key);
    },
    classStatus() {
      return {
        disabled: !this.isActive,
      };
    },
    createdAt() {
      if (this.campaign.createdAt) {
        const createdAt = this.$moment(this.campaign.createdAt);
        return createdAt.format('DD/MM/YYYY');
      }

      return 'N/A';
    },
  },
  mounted() {
    this.inputName = this.campaign.name;
  },
  watch: {
    inputName() {
      this.hasError = false;
    },
  },
  methods: {
    openInputName() {
      this.editName = true;
      this.inputName = this.campaign.name;

      this.$nextTick(() => {
        this.$refs.inputName.focus();
      });
    },
    saveName() {
      if (this.inputName.length === 0) {
        this.hasError = true;
      } else {
        if (this.inputName !== this.campaign.name) {
          const data = {
            id: this.campaign.id,
            name: this.inputName,
          };

          this.$emit('updated', data);
        }

        this.editName = false;
        this.hasError = false;
      }
    },
    cancelEditName() {
      this.editName = false;
      this.inputName = this.campaign.name;
    },
    toggleCampaign() {
      this.$swal({
        text: '¿Estás seguro de continuar?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      })
        .then((result) => {
          if (result.value) {
            const data = {
              id: this.campaign.id,
              active: !this.isActive,
            };

            this.$emit('updated', data);
          }
        });
    },
    openCampaignConfiguration() {
      this.$emit('campaign-configuration-opened', this.campaign.id);
    },
    openEdit() {
      this.$emit('edited', this.campaign.id);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_campaign-card.scss';
</style>
